.dashboard_content_section {
    margin-top: 40px;
}

.wrapper{
    background: #fff;
    border: 1px solid rgba(125, 125, 125, 0.43);
    border-radius: 6px;
    padding: 20px 30px;
}

.wrapper {
    margin-bottom: 30px;
}

.iconImage{
    height: 100px;
    width: 100px;
    margin: 0 auto;
}
.card{
    cursor: pointer;
    background-color: brown;
    color: whitesmoke;
}
.card:hover{
    opacity: 0.6;
}
.dashboard_title{
    /* padding: 5px;
    text-align: center;
    border: solid 1px grey; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    margin-bottom: 30px;
}

.dashboard_title h4 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 0;
}

.dashboard_title span {
    color: rgba(125, 125, 125, 1);
}

.dashboard_tile_container {
    padding: 20px 30px;
}