@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

.service_main_wrapper {
    padding: 70px 90px 90px 90px;
    position: relative;
    background-image: url('../../../assets/image/landing-images/service_circle_bg.png');
    background-size: 215px 300px;
    background-repeat: no-repeat;
    background-position: bottom right;
}

.service_main_wrapper::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.02;
    background-image: url('../../../assets/image/landing-images/service_bg.jpeg');
    background-size: cover;
    background-size: 60% 75%;
    background-repeat: no-repeat;
}

/* .service_main_wrapper::after {
    content: ' ';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 320px;
    height: 320px;
    background-image: url('../../../assets/image/landing-images/service_circle_bg.png');
    background-size: cover;
    background-size: 60% 85%;
    background-repeat: no-repeat;
} */

.service_sub_desc {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    position: relative;
}

.service_about {
    width: 100%;
}

.service_about h1 {
    /* font-family: "Jost", sans-serif; */
    font-weight: 200;
    font-size: 40px;
}

.service_about h1 span {
    /* font-family: "Atkinson Hyperlegible", sans-serif; */
    font-size: 45px;
    font-weight: 700;
    color: #16A66A;
}

.about_styled_text {
    color: #2D3D49;
}

.service_count h1 {
    /* width: 45%; */
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 45px;
    font-weight: 700;
    color: rgba(45, 61, 73, 1);
}

.service_count h1 span {
    color: #16A66A;
}

.service_count p {
    /* font-family: "Jost", sans-serif; */
    font-weight: 300;
    font-size: 18px;
    color: rgba(125, 125, 125, 1);
}

.service_graph_section {
    margin-top: 50px;
    margin-bottom: 70px;
}

.service_graph_img {
    /* width: 100%; */
    float: left;
    margin-right: 55px;
}

.service_main_desc {
    padding-top: 28px;
}

.service_main_desc h1 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 70px;
    font-weight: 700;
    color: rgba(217, 217, 217, 1);
    line-height: 70px;
}

.service_main_desc h1 span {
    color: #16A66A;
}

.service_main_desc p {
    /* font-family: "Jost", sans-serif; */
    font-weight: 300;
    font-size: 20px;
    color: rgba(125, 125, 125, 1);
    margin-top: 40px;
}

.main_desc_footer {
    display: flex;
    align-items: center;
    margin-top: 60px;
}

.main_desc_footer h4 {
    /* font-family: "Atkinson Hyperlegible", sans-serif; */
    font-size: 35px;
    font-weight: 700;
    color: rgba(217, 217, 217, 1);
}

.desc_footer_wrapper {
    width: 320px;
}

.desc_footer_wrapper span {
    color: #16A66A;
}

@media(min-width: 768px) {
    .service_main_wrapper {
        padding: 70px 45px 70px 45px;
    }

    .service_graph_img {
        width: 100%;
        margin-bottom: 30px;
    }

    .service_sub_desc {
        flex-direction: column;
        gap: 20px;
    }

    .main_desc_footer {
        gap: 20px;
    }

    .desc_footer_wrapper {
        width: auto;
    }
}

@media(min-width: 1024px) {
    .service_main_wrapper {
        padding: 40px;
    }

    .service_graph_img {
        width: 60%;
        margin-bottom: 0px;
    }

    .service_main_desc h1 {
        font-size: 50px;
        line-height: 60px;
    }

    .desc_footer_wrapper {
        width: auto;
    }

    .main_desc_footer {
        gap: 25px;
    }

    .service_sub_desc {
        flex-direction: row;
        gap: 0px;
    }
}

@media(min-width: 1100px) {
    .service_graph_img {
        margin-bottom: 20px;
    }

    .service_main_desc h1 {
        font-size: 60px;
        line-height: 65px;
    }
}

@media(min-width: 1200px) {
    /* .service_main_wrapper {
        padding: 80px;
    } */

    .service_main_desc h1 {
        font-size: 70px;
        line-height: 70px;
    }

    .service_graph_img {
        width: 60%;
        margin-bottom: 20px;
    }

    .service_graph_section {
        margin-bottom: 50px;
    }
}

@media(min-width: 1250px) {
    .service_main_wrapper {
        padding: 50px;
    }
}

@media(min-width: 1300px) {
    .service_main_wrapper {
        padding: 70px;
    }

    .service_graph_img {
        width: auto;
        margin-bottom: 10px;
    }

    .service_main_desc h1 {
        font-size: 70px;
        line-height: 70px;
    }

    .desc_footer_wrapper {
        width: auto;
    }

    .main_desc_footer {
        gap: 20px;
    }

    .service_graph_section {
        margin-bottom: 50px;
    }
}

@media(min-width: 1400px) {
    .service_graph_section {
        margin-bottom: 50px;
    }

    .desc_footer_wrapper {
        width: 320px;
    }

    .main_desc_footer {
        gap: 0px;
    }
}