/* ======== modal css start ========= */

.modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 7;
}

.modal,
.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49, 49, 49, 0.7);
}

.modal_btn_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    max-width: 80%;
    min-width: 450px;
    min-height: 400px;
}

.modal_content {
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 2px solid rgba(22, 166, 106, 1);
    border-radius: 10px;
}

.close_button {
    position: absolute;
    top: -10px;
    right: -10px;
    background: none;
    border: none;
    font-size: 25px;
    cursor: pointer;
    color: #fff;
    z-index: 2;
}

.modal_img_wrapper {
    margin-top: 20px;
}

.modal_img_wrapper img {
    width: 200px;
}

.modal_body {
    padding: 20px;
}

/* ======== modal css end ========= */