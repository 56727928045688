.testimonial_section_container {
    padding: 90px 70px;
    background-image: url('../../../assets/image/landing-images/testimonial-circle-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;
}

.testimonial_section_container::after {
    content: "TESTIMONIALS";
    position: absolute;
    bottom: -195px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 150px;
    font-weight: 700;
    color: rgba(217, 217, 217, 0.23);
}

.testimonial_section_header {
    text-align: center;
    margin-bottom: 70px;
}

.testimonial_section_header h3 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 45px;
    font-weight: 700;
}

.testimonial_section_header h3 span {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 70px;
    font-weight: 700;
    color: rgba(52, 164, 90, 1);
}

.testimonial_section_header p {
    font-family: "Jost", sans-serif;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    color: rgba(125, 125, 125, 1);
}

@media(min-width: 768px) {
    .testimonial_section_container {
        padding: 90px 35px;
    }

    .testimonial_section_container::after {
        font-size: 100px;
        bottom: -130px;
    }
}

@media(min-width: 1024px) {
    .testimonial_section_container {
        padding: 90px 70px;
    }

    .testimonial_section_container::after {
        font-size: 150px;
        bottom: -195px;
    }
}