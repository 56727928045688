.signup_container {
    /* background: rgb(237, 229, 229); */
    margin: 0 auto;
    padding: 5px;
    background-color: #3d6259;
 
}

.signup_card {
    box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.09);
    padding: 20px;
    border-radius: 20px;
    /* margin-top: 50px !important;
    margin-bottom: 50px; */
   background-color: snow;

.signup_header {
    text-align: center;
}

.signup_header span {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 45px;
    font-weight: 500;
    color: rgba(52, 164, 90, 1);
}

.form_wrapper {
    width: 90% !important;
    margin: 0 auto;
}

.form_group {
    margin-bottom: 10px;
}

.form_group input {
    width: 100%;
    border: 1px solid rgba(22, 166, 106, 1);
    height: 38px;
    border-radius: 6px;
    padding-left: 10px;
}

.form_group input:focus {
    outline: none;
}

.password_rules {
    font-size: 12px;
    list-style-type: circle;
    color: red;
    font-weight: 600;
}

.color_green {
    color: green !important;
}

.form_error {
    color: red;
    font-size: 12px;
    font-weight: 500;
}

.signup_btn {
    width: 100%;
    background-color: rgba(252, 149, 2, 1);
    height: 38px;
    border: none;
    margin-top: 25px;
    border-radius: 6px;
    color: white;
    margin-bottom: 20px;
}

.signup_img_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.signup_logo {
    /* position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 20px; */
    text-align: center;
}

.signup_logo 
{
    background-image: url('../../assets/image/landing-images/banner-bg_img.png');
    background-repeat: no-repeat;
    width: auto;
    height: 100%;
    background-size: cover;
}
    /* width: 300px;
    height: 86px; */
}

.signup_side_img img {
    width: 100%;
    height: 580px;
    border-radius: 20px;
}