.wrapper {
    /* background: lightgray; */
}

.sidebar_wrapper {
    background-color: rgba(28, 31, 38, 1);
}

.dashboard_sidebar {
    position: fixed;
    top: 0;
    left: -320px;
    width: 300px !important;
    height: 100%;
    background-color: rgba(28, 31, 38, 1);
    transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 1;
    overflow-y: scroll;
    z-index: 2;
    box-shadow: 4px 0px 17px 0px rgba(0, 0, 0, 0.45);
    padding: 5px 15px 10px 15px;
}

.dashboard_sidebar.active {
    left: 0 !important;
}

.close_section {
    display: flex;
    justify-content: space-between;
}

.close_section button {
    background-color: transparent;
    font-size: 30px;
    color: rgba(22, 166, 106, 1);
}

@media(max-width: 768px) {
    .sidebar_wrapper {
        display: none;
    }
}

@media(min-width: 768px) {
    .dashboard_sidebar {
        display: none;
    }

    .sidebar_wrapper {
        display: block;
    }
}