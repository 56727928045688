.wrapper{
    background: yellow;
}
/* LandingPage.module.css */

.container {
    /* max-width: 1200px; */
    margin: 0 auto;
    /* padding: 20px; */
  }
  
  .header {
    background-color: #20232a;
    color: #61dafb;
    padding: 10px 0;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navLinks {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .navLinks li {
    font-size: 18px;
  }
  
  .navLinks a {
    text-decoration: none;
    color: #61dafb;
    transition: color 0.3s ease;
  }
  
  .navLinks a:hover {
    color: #ffffff;
  }
  
  .logo img {
    max-height: 40px;
  }
  
  .hero {
    background-image: url('../../assets/image/back.jpg'); 
    background-size: cover;
    background-position: center;
    color: #ffffff;
    text-align: center;
    padding: 100px 0;
  }
  
  .hero h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .hero p {
    font-size: 20px;
    margin-bottom: 40px;
  }
  
  .ctaButton {
    background-color: #61dafb;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    background-color: #f8f8f8;
    padding: 50px 0;
  }
  
  .feature {
    text-align: center;
    padding: 20px;
    max-width: 300px;
  }
  
  .feature h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .feature p {
    font-size: 18px;
  }
  
  .contact {
    text-align: center;
    padding: 100px 0;
    background-color: #61dafb;
    color: #ffffff;
  }
  
  .contact h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .contact p {
    font-size: 20px;
    margin-bottom: 40px;
  }
  
  .contactButton {
    background-color: #ffffff;
    color: #61dafb;
    padding: 15px 30px;
    font-size: 20px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .contactButton:hover {
    background-color: #61dafb;
    color: #ffffff;
  }
  