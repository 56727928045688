@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

.main_container {
    background: linear-gradient(180deg, rgba(28, 31, 38, 0.7) 52.6%, rgba(45, 61, 73, 0.7) 85.6%);
}

.bg_img_container {
    background-image: linear-gradient(180deg, rgba(22, 166, 106, 0.25) 19.65%, rgba(37, 49, 58, 0.5) 75.55%), url('../../../assets/image/landing-images/banner-bg_img.png');
    background-size: cover;
    background-position: center;
    color: #ffffff;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    min-height: 900px;
}

.main_img_container {
    position: absolute;
    left: -22px;
    bottom: 0;
    top: 210px;
}

.banner_text_wrapper {
    width: 630px;
    text-align: left;
    position: absolute;
    left: 530px;
    top: 277px;
}

.banner_heading h1 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 105px;
    font-weight: 700;
    line-height: 95.13px;
}

.banner_text_content p {
    font-weight: 400;
    font-size: 33px;
    margin-bottom: 40px;
}

.banner_text_content p span {
    color: rgba(22, 166, 106, 1);
}

.banner_text_content button {
    background-color: rgba(22, 166, 106, 1);
    color: white;
    font-family: "Jost", sans-serif;
    font-weight: 700;
    font-size: 20px;
    width: 157px;
    height: 49px;
    /* padding: 5px 20px; */
    box-sizing: border-box;
    border-radius: 10px;
    border: rgba(22, 166, 106, 1);
}

.tootltip_img1 {
    position: absolute;
    left: 30vw;
    top: 267px;
    z-index: 1;
}

.tootltip_img3 {
    position: absolute;
    left: 68vw;
    top: 198px;
    z-index: 1;
}

.tootltip_img2 {
    position: absolute;
    left: 82vw;
    top: 485px;
    z-index: 1;
}

.tootltip_img4 {
    position: absolute;
    left: 90vw;
    top: 244px;
    z-index: 1;
}

.down_arrow {
    position: absolute;
    bottom: 55px;
    left: 50%;
}

.down_arrow span {
    font-size: 25px;
    color: white;
}

.product_intro_link {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 10px;
    right: 55px;
    bottom: 100px;
}

.product_intro_link a {
    color: rgba(22, 166, 106, 1);
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 15px;
    font-weight: 400;
}

@media(max-width: 1440px) {
    .banner_text_wrapper {
        width: 560px;
        left: 500px;
    }

    .banner_heading h1 {
        font-size: 90px;
    }

    .banner_text_content p {
        font-size: 25px;
    }
}

@media(max-width: 1200px) {
    .banner_text_wrapper {
        width: 45%;
        left: 440px;
    }

    .banner_heading h1 {
        font-size: 75px;
        line-height: 80px;
    }

    .banner_text_content p {
        font-size: 20px;
    }
}

@media(max-width: 1024px) {
    .banner_text_wrapper {
        width: 40%;
        left: 410px;
    }

    .banner_heading h1 {
        font-size: 55px;
        line-height: 60px;
    }

    .banner_text_content p {
        font-size: 20px;
    }

    .main_img_container {
        left: -50px;
    }

    .tootltip_img1 {
        top: 210px;
    }

    .tootltip_img2 {
        top: 545px;
    }
}

@media(min-width: 768px) {
    /* .banner_heading h1 {

    } */
}