.wrapper {
    background: white;
    margin-top: 20px;
    /* border: solid 1px red; */
}

.feature_head {
    padding: 5px;
    background: #eff6f9;
    font-size: 25px;
    text-align: center;
}

.filter_section {
    /* background: yellow; */
    border: 1px solid rgba(199, 199, 199, 1);
    border-radius: 6px;
    height: 76px;
    padding: 0px 20px;
    position: relative;
}

.filter_section::before {
    content: "";
    position: absolute;
    bottom: -19px;
    right: 20px;
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    display: none;
    filter: drop-shadow(0 0 0px rgba(0, 0, 0, 1));
}

.filter_section.adv_active::before {
    display: block;
}

.centered_button {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* gap: 20px; */
    width: 35%;
    /* padding: 15px 20px; */
}

.inp_btn_wrapper {
    display: flex;
    align-items: center;
    height: 100%;
}

.filter_inputs_wrapper {
    display: flex;
    align-items: center;
    width: 65%;
    height: 100%;
}

.watchlist_dropdown_wrapper {
    width: 25%;
}

.watchlist_dropdown_wrapper select {
    background: linear-gradient(180deg, #FFFFFF 30%, rgba(153, 153, 153, 0.07) 100%);
    border: 1px solid rgba(22, 166, 106, 1);
    height: 38px;
    background-image: url('../../assets/image/dashboard-images/Vector.svg');
    background-repeat: no-repeat;
    background-position: right 15px center;
    appearance: none;
    width: 100%;
    padding: 5px 10px;
    border-radius: 6px;
}

.watchlist_dropdown_wrapper select:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid rgba(22, 166, 106, 1);
}

.grey_text {
    color: grey;
}

.black_text {
    color: black;
}

select option {
    color: black;
}

.search_input_wrapper {
    width: 15%;
}

.search_input_wrapper input {
    background: linear-gradient(180deg, #FFFFFF 30%, rgba(153, 153, 153, 0.07) 100%);
    border: 1px solid rgba(22, 166, 106, 1);
    height: 38px;
}

.search_input_wrapper select {
    background: linear-gradient(180deg, #FFFFFF 30%, rgba(153, 153, 153, 0.07) 100%);
    border: 1px solid rgba(22, 166, 106, 1);
    height: 38px;
    background-image: url('../../assets/image/dashboard-images/Vector.svg');
    background-repeat: no-repeat;
    background-position: right 15px center;
    appearance: none;
    width: 100%;
    padding: 5px 10px;
    border-radius: 6px;
}

.search_input_wrapper select:focus,
.search_input_wrapper input:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid rgba(22, 166, 106, 1);
}

.filter_btn {
    background-color: rgba(22, 166, 106, 1);
    padding: 7px 20px;
    color: white;
    border-radius: 8px;
    font-family: "Jost", sans-serif;
    font-weight: 600;
    font-size: 14px;
}

.filter_clear_btn {
    background-color: rgba(37, 49, 58, 1);
    padding: 7px 20px;
    color: white;
    border-radius: 8px;
    font-family: "Jost", sans-serif;
    font-weight: 600;
    font-size: 14px;
}

.adv_search_btn {
    background: transparent;
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 13px;
    font-weight: 700;
    text-decoration: underline;
    color: rgba(37, 49, 58, 1);
    padding: 0;
}

.filter_dashed_line {
    width: 1px;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 35%, transparent 20%);
    background-size: 1px 10px;
    background-repeat: repeat-y;
    margin-right: 20px;
    margin-left: 20px;
}

/* advanced search section css starts */

.adv_search_section {
    border: 1px solid rgba(125, 125, 125, 0.43);
    border-radius: 6px;
    /* height: 76px; */
    padding: 0px 20px;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.13);
    margin-top: 20px;
    display: flex;
    align-items: center;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-out, opacity 0.3s ease-out;
    opacity: 0;
}

.adv_search_section.active {
    height: 76px;
    opacity: 1;
}

.adv_option_container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 85%;
}

.adv_input_search {
    width: 55%;
    display: flex;
    align-items: center;
}

.adv_input_search button {
    background-color: rgba(37, 49, 58, 1);
    width: 50px;
    height: 38px;
    text-align: center;
    color: white;
    font-family: "Jost", sans-serif;
    font-weight: 600;
    font-size: 14px;
    border-radius: 0px 8px 8px 0;
}

.adv_input_search input {
    width: calc(100% - 50px);
    height: 38px;
    background: linear-gradient(180deg, #FFFFFF 30%, rgba(125, 125, 125, 0.07) 100%);
    border: 1px solid rgba(22, 166, 106, 1);
    border-radius: 6px 0px 0px 6px;
    padding: 10px;
}

.adv_dropdown_wrapper {
    width: 20%;
}

.adv_dropdown_wrapper select {
    background: linear-gradient(180deg, #FFFFFF 30%, rgba(153, 153, 153, 0.07) 100%);
    border: 1px solid rgba(22, 166, 106, 1);
    height: 38px;
    width: 100%;
    border-radius: 6px;
    appearance: none;
    background-image: url('../../assets/image/dashboard-images/Vector.svg');
    background-repeat: no-repeat;
    background-position: right 15px center;
    padding: 5px 10px;
    border-radius: 6px;
}

.adv_dropdown_wrapper select:focus {
    outline: none;
}

.close_btn_wrapper {
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close_btn_wrapper button {
    background: transparent;
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 13px;
    font-weight: 700;
    text-decoration: underline;
    color: rgba(37, 49, 58, 1);
}

.close_btn_wrapper button img {
    margin-left: 10px;
}

.adv_dropdown_btn {
    display: none;
    font-size: 25px;
    color: rgba(199, 199, 199, 1);
}

.adv_dashed_line {
    width: 1px;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 35%, transparent 20%);
    background-size: 1px 10px;
    background-repeat: repeat-y;
    margin-right: 20px;
    margin-left: 20px;
}

@media(max-width: 1200px) {
    .filter_section {
        padding: 20px;
        height: 130px;
    }

    .filter_inputs_wrapper {
        height: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .watchlist_dropdown_wrapper {
        width: 47%;
    }

    .search_input_wrapper {
        width: 47%;
    }

    .filter_dashed_line {
        display: none;
    }

    .adv_dropdown_btn {
        display: block;
    }

    .adv_search_btn {
        display: none;
    }
} 

@media(max-width: 992px) {
    .centered_button {
        width: 100%;
        justify-content: center;
        gap: 20px;
    }
    
    /* .filter_inputs_wrapper {
        width: 60%;
    } */
}

@media(max-width: 992px) {
    .inp_btn_wrapper {
        flex-direction: column;
    }

    .filter_section {
        height: 180px;
    }

    .filter_inputs_wrapper {
        width: 100%;
    }

    .adv_dashed_line {
        display: none;
    }

    .adv_option_container {
        flex-wrap: wrap;
        gap: 15px;
        width: 80%;
    }

    .adv_input_search {
        width: 95%;
    }

    .adv_search_section.active {
        padding: 15px 20px;
        height: 130px;
    }

    .adv_dropdown_wrapper {
        width: 46%;
    }

    .close_btn_wrapper {
        width: 20%;
    }
}

@media(max-width: 600px) {
    .adv_option_container {
        width: 75%;
    }

    .close_btn_wrapper {
        width: 25%;
    }

    .close_btn_wrapper button img {
        width: 20px;
    }
}

@media(max-width: 425px) {
    .adv_dropdown_wrapper {
        width: 95%;
    }

    .adv_search_section.active {
        height: 175px;
    }
}