.footer_main_container {
    padding: 80px;
}

.footer_desc_section h1 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 45px;
    font-weight: 700;
    color: rgba(37, 49, 58, 1);
    margin-bottom: 15px;
}

.footer_desc_section span {
    color: #16A66A;
}

.footer_desc_section button {
    background-color: rgba(22, 166, 106, 1);
    width: 120px;
    height: 40px;
    font-family: "Jost", sans-serif;
    font-weight: 600;
    font-size: 18px;
    border-radius: 8px;
    color: white;
}

.footer_subscribe_section p {
    font-family: "Jost", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: rgba(125, 125, 125, 1);
}

.subscription_input_wrapper {
    display: flex;
    align-items: center;
}

.subscription_input_wrapper input {
    width: 280px;
    height: 45px;
    padding-left: 30px;
    border-radius: 8px 0 0px 8px;
    background-color: rgba(45, 61, 73, 1);
    font-family: "Jost", sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: rgba(217, 217, 217, 1);
    border: none;
}

.subscription_input_wrapper input:focus {
    outline: none;
}

.subscription_input_wrapper button {
    width: 150px;
    height: 45px;
    background-color: rgba(22, 166, 106, 1);
    border-radius: 0 8px 8px 0;
    color: white;
    font-family: "Jost", sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
}

.footer_address_section {
    padding-left: 150px;
}

.footer_address_section img {
    margin-bottom: 15px;
}

.footer_address_section p {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: rgba(83, 91, 101, 1);
}

.footer_address_section p span {
    font-weight: 700;
}

.footer_copyright_section {
    background-color: rgba(28, 31, 38, 1);
    padding: 10px 80px;
}

.copyright_text p {
    margin-bottom: 0;
    color: rgba(83, 91, 101, 1);
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-top: 8px;
}

.footer_links {
    text-align: right;
}

.footer_links p {
    margin-bottom: 0;
    color: rgba(83, 91, 101, 1);
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-top: 8px;
}

@media(min-width: 768px) {
    .footer_address_section {
        padding-left: 0px;
        margin-top: 40px;
    }

    .footer_main_container {
        padding: 80px 35px;
    }
}

@media(min-width: 1024px) {
    .footer_address_section {
        padding-left: 50px;
    }

    .footer_main_container {
        padding: 80px;
    }
}

@media(min-width: 1200px) {
    .footer_address_section {
        padding-left: 90px;
    }
}

@media(min-width: 1400px) {
    .footer_address_section {
        padding-left: 150px;
    }
}