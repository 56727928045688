.thickwallet-grid-container {
  margin-top: 20px;
  width: 100%;
  /* background: #bebcbc; */
}

.thickwallet-grid-container .grid {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  border: 1px solid rgba(125, 125, 125, 0.43);
  border-radius: 6px;
  height: 570px;
  overflow-y: scroll;
}

/* Scrollbar styling */
.thickwallet-grid-container .grid::-webkit-scrollbar {
  width: 6px;
}

.thickwallet-grid-container .grid::-webkit-scrollbar-track {
  background: rgba(125, 125, 125, 0.17);
  border-radius: 70px;
}

.thickwallet-grid-container .grid::-webkit-scrollbar-thumb {
  background: rgba(22, 166, 106, 1);
  border-radius: 70px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.thickwallet-grid-container .grid::-webkit-scrollbar-thumb:hover {
  background: rgb(17, 114, 73);
}

.thickwallet-grid-container .card {
  flex: 0 0 calc(25% - 20px);
  /* width: 250px; */
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.thickwallet-grid-container .pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.thickwallet-grid-container .pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 3px;
}

.thickwallet-grid-container .pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* ------------------------------------- */

.pagination-container {
  display: flex;
  justify-content: center;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.pagination-wrapper button {
  background-color: transparent;
  color: black;
  font-size: 20px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.disabled-button {
  background-color: transparent !important;
  color: rgba(125, 125, 125, 1) !important;
  cursor: default !important;
}

.pagination-item-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pagination-item-wrapper span {
  cursor: pointer;
}

.pagination-item {
  background-color: rgba(125, 125, 125, 0.8);
  color: white;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
}

.active-notifications {
  color: white;
  background-color: rgba(22, 166, 106, 1);
  font-size: 12px;
  width: 25px;
  height: 25px;
  border: 1px solid ;
  border-radius: 100px;
  text-align: center;
  line-height: 24px;
}

/* ------------------------------------- */

@media only screen and (max-width: 600px) {
  .thickwallet-grid-container .card {
    flex: 0 0 calc(50% - 20px);
    margin: 10px;
    padding: 10px;
  }
}