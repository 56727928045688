.insights_card_wrapper {
    box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.07);
    border: 1px solid rgba(125, 125, 125, 0.17);
    border-radius: 6px;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
    cursor: pointer;
}


.insights_card_wrapper:hover {
    transform: scale(1.01);
    border: 2px solid rgba(22, 166, 106, 1);
}

.insights_card_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.insights_card_top h4 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
}

.insights_logo_wrapper {
    width: 45px;
    height: 45px;
}

.insights_logo_wrapper img {
    width: 100%;
    height: 100%;
    border-radius: 100px;
}

.card_dashed_line {
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 35%, transparent 20%);
    background-size: 10px 1px;
    background-repeat: repeat-x;
}

.insights_card_body {
    padding: 15px;
}

.card_body_desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card_body_desc:nth-child(1) {
    margin-bottom: 15px;
}

.card_body_desc h5 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
}

.card_body_desc p {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
    color: rgba(234, 37, 50, 1);
}

.insights_card_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.card_footer_trend {
    display: flex;
    align-items: center;
    gap: 10px;
}

.card_footer_trend p {
    margin-bottom: 0;
    font-family: "Jost", sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: rgba(125, 125, 125, 1);
}


/* insights card 2 styles */

.downtrend_card_desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.downtrend_card_desc h4 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 0;
}

.downtrend_card_desc h5 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
}

.downtrend_card_desc p {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0;
    color: rgba(234, 37, 50, 1);
}
