.pricing_section_container {
    background-color: rgba(28, 31, 38, 1);
    padding: 70px;
    background-image: url('../../../assets/image/landing-images/pricing-bg.png');
    background-repeat: no-repeat;
    background-position: left 60px;
}

.pricing_top_section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top_section_header {
    display: flex;
    align-items: center;
    gap: 20px;
}

.top_section_header h1 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 45px;
    font-weight: 700;
    color: #7A7B7F;
    margin-bottom: 0;
}

.top_section_header h1 span {
    color: rgba(52, 164, 90, 1);
}

.pricing_top_section button {
    background-color: rgba(22, 166, 106, 1);
    color: white;
    font-family: "Jost", sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    width: 160px;
    height: 40px;
    border-radius: 10px;
    margin-top: 25px;
}

.plan_selection_header {
    text-align: center;
    margin-top: 120px;
}

.plan_selection_header h6 {
    font-family: "Jost", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: white;
    text-transform: uppercase;
    margin-bottom: 25px;
}

.plan_selection_header h1 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 45px;
    font-weight: 700;
    color: white;
    margin-bottom: 25px;
}

.plan_selection_header h1 span {
    color: rgba(52, 164, 90, 1);
}

.plan_selection_header p {
    font-family: "Jost", sans-serif;
    font-weight: 300;
    font-size: 20px;
    color: rgba(125, 125, 125, 1);
}

.tab_list_wrapper {
    margin-top: 50px;
}

.tab_list {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;
}

.tab-item {
    cursor: pointer;
    color: #fff;
    background-color: rgba(37, 49, 58, 1);
}

.tab-item:nth-child(1) {
    border-radius: 10px 0 0 10px;
    border-right: 1px solid rgba(83, 91, 101, 1);
}

.tab-item:nth-child(2),
.tab-item:nth-child(3),
.tab-item:nth-child(4) {
    border-right: 1px solid rgba(83, 91, 101, 1);
}

.tab-item:nth-child(5) {
    border-radius: 0 10px 10px 0;
}

.tab-item.active {
    color: #fff;
    background-color: rgba(22, 166, 106, 1);
}

/* .tab-item:hover {
    background: rgba(22, 166, 106, 1);
} */

.tab_list li {
    text-align: left;
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 700;
    height: 45px;
    width: 185px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.tab_content {
    margin-top: 60px;
}

.tab-wrapper {
    display: none;
}

.show-tab-wrapper {
    display: block;
}

/* ---------------- */

.pricing_cards_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.pricing_card_container {
    background-color: rgba(45, 61, 73, 0.6);
    box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 350px;
    position: relative;
}

.pricing_discount_banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 300px;
    background-color: rgba(125, 125, 125, 0.17);
    clip-path: polygon(25% 0%, 40% 0, 0 40%, 0 25%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.discount_banner_text1 {
    transform: rotate(-45deg);
    color: #fff;
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 15px;
    font-weight: 700;
    white-space: nowrap;
    position: absolute;
    top: 38px;
    left: 8px;
}

.discount_banner_text2 {
    transform: rotate(-45deg);
    color: #fff;
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 15px;
    font-weight: 700;
    white-space: nowrap;
    position: absolute;
    top: 35px;
    left: 8px;
}

.discount_banner_text3 {
    transform: rotate(-45deg);
    color: #fff;
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 15px;
    font-weight: 700;
    white-space: nowrap;
    position: absolute;
    top: 35px;
    left: 8px;
}

.pricing_card_header {
    text-align: center;
    padding: 0 30px;
}

.pricing_card_header h3 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 25px;
    font-weight: 700;
    color: rgba(217, 217, 217, 1);
    margin-bottom: 35px;
    padding-top: 70px;
}

.pricing_card_header h3 span {
    color: rgba(52, 164, 90, 1);
}

.pricing_card_header h1 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 65px;
    font-weight: 700;
    color: white;
    margin-bottom: 40px;
}

.pricing_card_header h1 span {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #7D7D7D;
}

.pricing_card_container:nth-child(1) .pricing_card_header p {
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: rgba(217, 217, 217, 1);
    margin-bottom: 70px;
}

.pricing_card_container:nth-child(2) .pricing_card_header p {
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: rgba(217, 217, 217, 1);
    margin-bottom: 70px;
}

.pricing_card_container:nth-child(3) .pricing_card_header p {
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: rgba(217, 217, 217, 1);
    margin-bottom: 45px;
}

.pricing_features_container {
    text-align: center;
    padding-bottom: 40px;
}

.pricing_features_container ul {
    list-style-type: none;
    padding: 0;
    /* background-image: linear-gradient(to right, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 50%, transparent 50%);
    background-size: 28px 1px;
    background-repeat: repeat-x; */
}

.pricing_features_container ul li {
    padding: 15px 50px;
    color: rgba(217, 217, 217, 1);
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 16px;
    font-weight: 400;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 50%, transparent 50%);
    background-size: 28px 1px;
    background-repeat: repeat-x;
}

.pricing_features_container ul li:nth-child(1) {
    font-size: 18px;
    font-weight: 700;
    color: rgba(22, 166, 106, 1);
}

.excluded_features {
    color: rgba(255, 255, 255, 0.2) !important;
    text-decoration: line-through;
}

.pricing_features_container button {
    width: 160px;
    height: 40px;
    background-color: rgba(22, 166, 106, 1);
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    border-radius: 10px;
}

@media(min-width: 768px) {
    .tab_list li {
        width: 125px;
    }

    .pricing_section_container {
        padding: 70px 35px;
    }

    .pricing_cards_wrapper {
        gap: 10px;
    }

    .pricing_card_container {
        width: 33%;
    }

    .pricing_card_header h1 {
        font-size: 45px;
    }

    .pricing_discount_banner {
        width: 225px;
        height: 225px;
    }

    .discount_banner_text1 {
        top: 25px;
        left: -3px;
    }

    .discount_banner_text2 {
        top: 24px;
        left: -6px;
    }

    .discount_banner_text3 {
        top: 24px;
        left: -6px;
    }

    .pricing_card_header h3 {
        font-size: 24px;
    }

    .pricing_card_container:nth-child(1) .pricing_card_header p,
    .pricing_card_container:nth-child(2) .pricing_card_header p,
    .pricing_card_container:nth-child(3) .pricing_card_header p {
        margin-bottom: 45px;
    }

    .top_section_header h1 {
        font-size: 40px;
    }
}

@media(min-width: 1024px) {
    .tab_list li {
        width: 175px;
    }

    .pricing_card_container {
        width: 350px;
    }

    .pricing_card_container:nth-child(1) .pricing_card_header p {
        margin-bottom: 70px;
    }
    
    .pricing_card_container:nth-child(2) .pricing_card_header p {
        margin-bottom: 70px;
    }
    
    .pricing_card_container:nth-child(3) .pricing_card_header p {
        margin-bottom: 45px;
    }

    .pricing_discount_banner {
        width: 300px;
        height: 300px;
    }

    .discount_banner_text1 {
        top: 38px;
        left: 8px;
    }

    .discount_banner_text2 {
        top: 35px;
        left: 8px;
    }

    .discount_banner_text3 {
        top: 35px;
        left: 8px;
    }

    .top_section_header h1 {
        font-size: 45px;
    }
}

@media(min-width: 1200px) {
    .tab_list li {
        width: 185px;
    }
}