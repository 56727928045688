.slider_container {
    width: 90%;
    /* margin: 0 auto; */
}

.feature_slider_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}

.feature_slider_card img {
    margin: 0 auto;
}

.slider_ctrl_btn {
    background-color: transparent;
    color: white;
}