.wrapper {
  background: #453483;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #000;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.1);
}

.header_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_left {
  display: flex;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.title {
  font-size: 24px;
}

.header_right {
  display: flex;
  align-items: center;
}

.profile_icon {
  width: 40px;
  height: 40px;
}

.modal_size {
  width: 0 !important;
  height: 0 !important;
}

.modal_top_right {
  position: fixed !important;
  top: 70px !important;
  right: 240px;
  margin: 0 !important;
  transform: none;
  min-width: 150px;
  z-index: 1050;
  /* Ensure it appears above other content */
}

.modal_top_right p {
  color: red;
  cursor: pointer;
}

.upgrade_btn {
  background-color: rgba(252, 149, 2, 1);
  color: white;
  width: 120px;
  height: 40px;
  border-radius: 8px;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-left: 20px;
}

.username_section {
  font-family: "Jost", sans-serif;
  font-weight: 400;
  font-size: 15px;
  font-style: italic;
  margin-bottom: 0;
  margin-left: 20px;
}

.username_section span {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: rgba(22, 166, 106, 1);
}

.notification_icon_wrapper {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 20px;
}

.notification_icon_wrapper img {
  width: 20px;
  height: 20px;
  margin: 0 auto;
}

.notification_count {
  position: absolute;
  top: -8px;
  right: -5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  border-radius: 100px;
  text-align: center;
  background-color: rgba(255, 3, 3, 1);
  color: white;
}

.hamburger_btn {
  display: none;
  flex-direction: column;
  cursor: pointer;
  background-color: transparent;
  color: black;
  font-size: 30px;
}


@media(max-width: 768px) {
  .hamburger_btn {
    display: block;
  }
}