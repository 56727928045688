.features_card_wrapper {
    box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.07);
    border: 1px solid rgba(125, 125, 125, 0.17);
    background-color: white;
    border-radius: 6px;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.locked{
    opacity: 0.5;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: auto;
    background-image: url('../../assets/image/lock.png');
    background-position: center;
    background-size: 20% 20%;
    background-repeat: no-repeat;
}
.features_card_wrapper:hover {
    transform: scale(1.01);
    border: 2px solid rgba(22, 166, 106, 1);
}

.features_card_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.card_top_header {
    display: flex;
    align-items: center;
    gap: 20px;
}

.card_top_header h4 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: black;
    margin-bottom: 0;
}

.card_dashed_line {
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 35%, transparent 20%);
    background-size: 10px 1px;
    background-repeat: repeat-x;
}

.features_card_body {
    display: flex;
    align-items: baseline;
    padding: 15px;
    gap: 10px;
}

.features_card_body span {
    color: rgba(22, 166, 106, 1);
    font-size: 20px;
}

.card_body_desc h5 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
}

.card_body_desc p {
    font-family: "Jost", sans-serif;
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 10px;
}
