.rs-table{
    border: solid 1px;
    border-radius: 9px;
}
.rs-table-row-header .rs-table-cell {
    background-color: #333333;
    color: whitesmoke
}
.rs-table-row-header{
    pointer-events: none;
}