.slider_container {
    width: 96%;
    /* margin: 0 auto; */
}

.testimonial_slider_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}

.testimonial_slider_card {
    background-color: rgba(37, 49, 58, 1);
    width: 400px !important;
    position: relative;
    padding: 50px 35px;
    box-sizing: border-box;
    border-radius: 10px;
}

.testimonial_slider_card img {
    /* margin: 0 auto; */
}

.slider_card_body img {
    position: absolute;
    top: -30px;
    left: 55px;
}

.slider_card_body p {
    font-family: "Jost", sans-serif;
    font-size: 20px;
    font-weight: 300;
    color: rgba(217, 217, 217, 1);
}

.slider_card_footer {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
}

.slider_card_footer h6 {
    /* font-family: "Jost", sans-serif; */
    font-size: 16px;
    font-weight: 700;
    color: white;
}

.slider_ctrl_btn {
    background-color: transparent;
    color: rgba(125, 125, 125, 1);
}

@media(min-width: 768px) {
    .testimonial_slider_card {
        width: 288px !important;
    }

    .slider_container {
        width: 93%;
    }
}

@media(min-width: 1024px) {
    .testimonial_slider_card {
        width: 275px !important;
    }
}

@media(min-width: 1200px) {
    .testimonial_slider_card {
        width: 325px !important;
    }
}

@media(min-width: 1440px) {
    .testimonial_slider_card {
        width: 400px !important;
    }
}