.wrapper {
    background: white;
    /* border: solid 1px red; */
    margin-top: 20px;
}

.feature_head {
    padding: 5px;
    background: #eff6f9;
    font-size: 25px;
    text-align: center;
}

.filter_section {
    border: 1px solid rgba(199, 199, 199, 1);
    border-radius: 6px;
    height: 76px;
    padding: 0px 20px;
    position: relative;
    display: flex;
    align-items: center;
}

.trends_dropdown_wrapper {
    width: 35%;
}

.trends_dropdown_wrapper select {
    background: linear-gradient(180deg, #FFFFFF 30%, rgba(153, 153, 153, 0.07) 100%);
    border: 1px solid rgba(22, 166, 106, 1);
    height: 38px;
    background-image: url('../../assets/image/dashboard-images/Vector.svg');
    background-repeat: no-repeat;
    background-position: right 15px center;
    appearance: none;
    width: 100%;
    padding: 5px 10px;
    border-radius: 6px;
}

.trends_dropdown_wrapper select:focus {
    outline: none;
}

.filter_dashed_line {
    width: 1px;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 35%, transparent 20%);
    background-size: 1px 10px;
    background-repeat: repeat-y;
    margin-right: 20px;
    margin-left: 20px;
}

.trends_filter_btn {
    background-color: rgba(22, 166, 106, 1);
    padding: 7px 20px;
    color: white;
    border-radius: 8px;
    font-family: "Jost", sans-serif;
    font-weight: 600;
    font-size: 14px;
}

.centered_button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.closing_style {
    margin-top: 20px;
}

.closing_style span {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.closing_style input {
    display: inline-block;
    margin-left: 10px;
}

.closing_basis_btn {
    border: 1px solid rgba(22, 166, 106, 1) !important;
    background-color: transparent;
    color: rgba(22, 166, 106, 1);
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.closing_basis_btn.active {
    background-color: rgba(22, 166, 106, 1);
    color: white;
}




.grey_text {
    color: grey;
}

.black_text {
    color: black;
}

select option {
    color: black;
}

.trends_card_grid {
    padding: 20px;
    border: 1px solid rgba(125, 125, 125, 0.43);
    border-radius: 6px;
    height: 570px;
    overflow-y: scroll;
    margin-top: 20px;
}

.trends_card_grid::-webkit-scrollbar {
    width: 6px;
}

.trends_card_grid::-webkit-scrollbar-track {
    background: rgba(125, 125, 125, 0.17);
    border-radius: 70px;
}

.trends_card_grid::-webkit-scrollbar-thumb {
    background: rgba(22, 166, 106, 1);
    border-radius: 70px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.trends_card_grid::-webkit-scrollbar-thumb:hover {
    background: rgb(17, 114, 73);
}

/* ===== card css starts ========= */

.trends_card_wrapper {
    box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.07);
    border: 1px solid rgba(125, 125, 125, 0.17);
    border-radius: 6px;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.trends_card_wrapper:hover {
    transform: scale(1.01);
    border: 2px solid rgba(22, 166, 106, 1);
}

.trends_card_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.trends_card_top h4 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0;
}

.trends_logo_wrapper {
    width: 45px;
    height: 45px;
}

.trends_logo_wrapper img {
    width: 100%;
    height: 100%;
    border-radius: 100px;
}

.card_dashed_line {
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 35%, transparent 20%);
    background-size: 10px 1px;
    background-repeat: repeat-x;
}

.trends_card_body {
    padding: 15px;
}

.card_body_desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card_body_desc:nth-child(1) {
    margin-bottom: 15px;
}

.card_body_desc h5 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
}

.card_body_desc p {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
    color: rgba(234, 37, 50, 1);
}

.trends_card_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.card_footer_trend {
    display: flex;
    align-items: center;
    gap: 10px;
}

.card_footer_trend p {
    margin-bottom: 0;
    font-family: "Jost", sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: rgba(125, 125, 125, 1);
}

/* ===== card css ends ========== */

/* ------------------------------------- */

.pagination-container {
    display: flex;
    justify-content: center;
}

.pagination-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.pagination-wrapper button {
    background-color: transparent;
    color: black;
    font-size: 20px;
    border: none;
    border-radius: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.disabled-button {
    background-color: transparent !important;
    color: rgba(125, 125, 125, 1) !important;
    cursor: default !important;
}

.pagination-item-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.pagination-item-wrapper span {
    cursor: pointer;
}

.pagination-item {
    background-color: rgba(125, 125, 125, 0.8);
    color: white;
    border: none;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    text-align: center;
    font-size: 12px;
    line-height: 24px;
}

.active-notifications {
    color: white;
    background-color: rgba(22, 166, 106, 1);
    font-size: 12px;
    width: 25px;
    height: 25px;
    border: 1px solid;
    border-radius: 100px;
    text-align: center;
    line-height: 24px;
}

/* ------------------------------------- */

@media(max-width: 1150px) {
    .card_body_desc {
        flex-direction: column;
    }

    .trends_logo_wrapper {
        width: 30px;
        height: 30px;
    }
}

@media(max-width: 600px) {
    .filter_section {
        flex-wrap: wrap;
        gap: 10px;
    }

    .filter_dashed_line {
        display: none;
    }

    .trends_dropdown_wrapper:nth-child(1) {
        width: 100%;
    }

    .trends_dropdown_wrapper:nth-child(3) {
        width: 80%;
    }

    .filter_section {
        padding: 15px 20px;
        height: 120px;
    }
}

@media(max-width: 525px) {
    .trends_dropdown_wrapper:nth-child(3) {
        width: 70%;
    }
}

@media(max-width: 380px) {
    .trends_dropdown_wrapper:nth-child(3) {
        width: 60%;
    }
}
