.breadcrumb_container {
    border: 1px solid rgba(125, 125, 125, 0.43);
    background-color: white;
    padding: 20px 40px;
    width: 100%;
    /* display: flex;
    flex-wrap: nowrap; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
    grid-gap: 10px;
    align-items: center;
    justify-items: center;
    border-radius: 6px;
    margin-top: 40px;
}

.breadcrumb_page_btn {
    /* margin-right: 50px; */
    text-align: center;
}

.breadcrumb_page_btn:last-child {
    margin-right: 0;
}

.dashboard_icon_container {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 100px;
    background-color: rgba(252, 149, 2, 1);
    margin-bottom: 10px;
}

.page_icon_container {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 100px;
    background-color: rgba(199, 199, 199, 1);
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.active_icon_container {
    background-color: rgba(22, 166, 106, 1);
}

.breadcrumb_page_name {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 15px;
    font-weight: 400;
}

@media(max-width: 1067px) {
    .breadcrumb_container {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        grid-gap: 15px;
    }
}

@media(max-width: 992px) {
    .breadcrumb_container {
        grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
        grid-gap: 15px;
    }
}