.wrapper {
    /* background: #f4f1ed; */
    /* border: solid 1px rgb(185, 175, 175); */
}

.feature_head {
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
    background: rgba(28, 31, 38, 1);
    font-size: 25px;
}

.feature_head img {
    max-width: 100%;
}

.search {
    padding: 9px;
    /* background: #ebdede; */
}

.option {
    padding: 5px;
    border-bottom: dashed 1px rgb(185, 175, 175);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.option button:first-child {
    pointer-events: none;
    background-color: transparent;
    font-family: "Jost", sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.option button:last-child {
    /* float: right; */
    background: rgba(22, 166, 106, 1);
    color: white;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    line-height: 15px;
    cursor: pointer;
}

.added_stocks ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}

.added_stocks ul li {
    padding: 7px;
    border-bottom: dashed 1px rgb(185, 175, 175);
    color: white;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.added_stocks ul li span {
    font-family: "Jost", sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.added_stocks ul li button {
    /* float: right; */
    background: red;
    color: white;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    line-height: 15px;
}

.list_style {
    list-style-type: none;
    padding-left: 0;
}

.list_style li {
    /* border: solid 1px #d0c1c1; */
    margin-bottom: 20px;
}

.list_title {
    padding: 12px;
    background-color: rgba(37, 49, 58, 1);
    color: white;
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 16px;
    font-weight: 400;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.list_title button {
    float: right;
    background: transparent;
    color: white;
}

.sidebar_accordion_container {
    margin-top: 50px;
}
