.navbar_container {
    background-color: transparent;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    padding: 10px 40px;
}

.navbar_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar_logo {
    display: flex;
    align-items: center;
    gap: 30px;
    width: 300px;
}

.navbar_options {
    display: flex;
    align-items: center;
    list-style-type: none;
    gap: 55px;
    margin-bottom: 0;
    padding-left: 0;
}

.navbar_options li a {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: white;
    text-decoration: none;
}

.features_dropdown {
    position: relative;
    cursor: pointer;
}

.nav_feature_option {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: white;
    text-decoration: none;
}

.nav_feature_option p {
    margin-bottom: 0;
}

.features_dropdown_content {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    list-style: none;
    padding: 1rem;
    min-width: 200px !important;
    text-align: left;
    /* margin-top: 10px; */
    border-radius: 0 0 20px 20px;
}

.features_dropdown:hover .features_dropdown_content {
    display: block;
}

.features_dropdown_content li {
    padding: 10px;
    color: black;
}

.features_dropdown_content li:hover {
    background-color: #F4F1F1;
}

.login_link {
    text-decoration: none;
}

.login_link:hover {
    text-decoration: none;
}

.login_btn {
    background-color: rgba(252, 149, 2, 1);
    color: white;
    width: 120px;
    height: 40px;
    border-radius: 8px;
    font-family: "Jost", sans-serif;
    font-weight: 600;
    font-size: 18px;
}

.hamburger_btn {
    display: none;
    flex-direction: column;
    cursor: pointer;
    background-color: transparent;
    color: white;
    font-size: 30px;
}

.nav_sidebar {
    position: fixed;
    top: 0;
    left: -320px;
    width: 300px;
    height: 100%;
    background-color: rgba(28, 31, 38, 1);
    transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 1;
    overflow-y: scroll;
    z-index: 2;
    box-shadow: 4px 0px 17px 0px rgba(0, 0, 0, 0.45);
    padding: 5px 15px 10px 15px;
}

.nav_sidebar.active {
    left: 0;
}

.close_section {
    display: flex;
    justify-content: space-between;
}

.close_section button {
    background-color: transparent;
    font-size: 30px;
    color: rgba(22, 166, 106, 1);
}

.sidebar_options {
    list-style-type: none;
    padding-left: 0;
}

.sidebar_options a {
    text-decoration: none;
    color: white;
}

.sidebar_options a li {
    padding: 15px 10px 15px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.sidebar_login_btn {
    background-color: transparent;
    padding: 15px 10px 15px 20px;
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: rgba(252, 149, 2, 1);
}

.features_accordion_wrapper {
    text-decoration: none;
    color: white;
    padding: 15px 10px 15px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.features_accordion_option {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.features_accordion.accordion_active .features_sub_options {
    max-height: 500px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.features_sub_options {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    padding-left: 20px;
}

.features_sub_options a {
    display: block;
    padding: 5px 0;
    margin-top: 1rem;
}

/* ======== modal css start ========= */

.form_input_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin-top: 15px;
}

.form_input_wrapper input {
    width: 100%;
    background: linear-gradient(180deg, #FFFFFF 30%, rgba(125, 125, 125, 0.07) 100%);
    border: 1px solid rgba(22, 166, 106, 1);
    height: 38px;
    border-radius: 6px;
    padding-left: 10px;
}

.form_input_wrapper input:focus {
    outline: none;
}

.login_form_container button {
    width: 100%;
    background-color: rgba(22, 166, 106, 1);
    height: 38px;
    border: none;
    margin-top: 25px;
    border-radius: 6px;
    color: white;
    margin-bottom: 20px;
}

/* ======== modal css end ========= */

@media(max-width: 1024px) {
    .hamburger_btn {
        display: block;
    }

    .navbar_options,
    .login_btn {
        display: none;
    }
}

@media(min-width: 1025px) {
    .hamburger_btn {
        display: none;
    }

    .navbar_options {
        display: flex;
    }

    .login_btn {
        display: block;
    }

    .nav_sidebar {
        display: none;
    }
}
