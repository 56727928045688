.features_main_container {
    background-color: #1C1F26;
    padding: 80px;
    background-image: url('../../../assets/image/landing-images/Vector\ 8.png');
    background-repeat: no-repeat;
    background-position: bottom left;
    position: relative;
}

.features_main_container::after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/image/landing-images/features-graph-line.png');
    background-repeat: no-repeat;
    background-position: 512px 585px;
}

.slider_header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 50px;
}

.slider_header h6 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 25px;
    font-weight: 400;
    color: white;
    margin-bottom: 0;
}

.features_desc_main_section {
    margin-top: 90px;
}

.features_desc_text h1 {
    /* font-family: "Jost", sans-serif; */
    font-weight: 200;
    font-size: 45px;
    color: white;
}

.desc_text_feature {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 45px;
    font-weight: 700;
    color: rgba(52, 164, 90, 1);
}

.desc_text_degree {
    /* font-family: "Jost", sans-serif; */
    font-weight: 500;
    font-size: 45px;
    color: #535B65;
}

.features_desc_text p {
    /* font-family: "Jost", sans-serif; */
    font-weight: 300;
    font-size: 20px;
    color: rgba(125, 125, 125, 1);
}

.features_read_more {
    display: flex;
    align-items: center;
    gap: 10px;
}

.features_read_more p {
    /* font-family: "Jost", sans-serif; */
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 0;
    color: white;
}

.trading_btn {
    width: 255px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
    background-color: rgba(37, 49, 58, 1);
    border-radius: 8px;
    margin-top: 90px;

}

.trading_btn span {
    /* font-family: "Jost", sans-serif; */
    font-weight: 400;
    font-size: 30px;
    color: white;
}

.trading_btn p {
    margin-bottom: 0;
    font-size: 30px;
    color: rgba(22, 166, 106, 1);
}

.features_img_section {
    
    position: relative;
    width: 100%;
    height: 100%;
    
}

.features_img_section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../../assets/image/landing-images/features-img.png');
    background-repeat: no-repeat;
    mix-blend-mode: luminosity;
    border-radius: 10px;
}

.video_ctrl_txt_btn {
    position: absolute;
    z-index: 1;
    bottom: 80px;
    left: -25px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.video_ctrl_txt_btn p {
    margin-bottom: 0;
    color: white;
}

@media(min-width: 768px) {
    .features_main_container {
        padding: 80px 40px;
    }

    .features_img_section::before {
        background-size: cover;
    }
}

@media(min-width: 1024px) {
    .features_main_container {
        padding: 80px 40px;
    }

    .features_img_section::before {
        background-size: auto;
    }
}

@media(min-width: 1200px) {
    .features_main_container {
        padding: 80px;
    }
}