.sale_section_container {
    position: relative;
    padding: 70px 70px 0px 70px;
}

.sale_section_container::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.02;
    background-image: url('../../../assets/image/landing-images/service_bg.jpeg');
    background-size: cover;
    background-size: 60% 75%;
    background-repeat: no-repeat;
}

.progress_bar_section h1 {
    font-family: "Jost", sans-serif;
    font-weight: 200;
    font-size: 45px;
}

.progress_bar_section h1 span {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 45px;
    font-weight: 700;
    color: rgba(52, 164, 90, 1);
}

.progress_bar_section button {
    background-color: rgba(37, 49, 58, 1);
    color: white;
    padding: 10px 35px;
    border-radius: 10px;
}

.sale_section_header h1 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 70px;
    font-weight: 700;
    color: rgba(217, 217, 217, 1);
}

.trade {
    color: rgba(52, 164, 90, 1);
}

.xcel {
    color: black;
}

.mobile_trading_wrapper {
    display: flex;
    gap: 20px;
    /* align-items: center; */
    margin-top: 155px;
}

.mobile_trading_header {
    margin-top: 130px;
}

.mobile_trading_header h6 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: rgba(125, 125, 125, 1);
}

.mobile_trading_header h3 {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 55px;
    font-weight: 700;
    color: rgba(52, 164, 90, 1);
}

.laptop_trading_wrapper {
    /* margin-top: 155px; */
    position: relative;
    top: 80px;
}

.vdo_play_btn {
    position: absolute;
    top: 10px;
    left: 60px;
    display: flex;
    gap: 25px;
}

.vdo_play_btn p {
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-size: 25px;
    font-weight: 700;
    color: black;
}

.vdo_play_btn img {
    width: 100%;
    height: 100%;
}

@media(min-width: 768px) {
    .mobile_trading_wrapper img {
        width: 100%;
    }

    .laptop_trading_wrapper img {
        width: 100%;
    }

    .vdo_play_btn {
        left: -5px;
    }

    .vdo_play_btn p {
        font-size: 18px;
    }
}

/* @media(min-width: 900px) {
    .vdo_play_btn {
        left: -5px;
    }

    .vdo_play_btn p {
        font-size: 18px;
    }
} */

@media(min-width: 1024px) {
    .mobile_trading_wrapper img {
        width: 55%;
    }

    .laptop_trading_wrapper {
        top: 40px;
    }

    .laptop_trading_wrapper img {
        width: 100%;
    }

    .vdo_play_btn {
        left: -15px;
    }

    .vdo_play_btn p {
        font-size: 25px;
    }

    .mobile_trading_header {
        margin-top: 55px;
    }

    .sale_section_container {
        padding: 70px 40px 0px 40px;
    }
}

@media(min-width: 1200px) {
    .mobile_trading_wrapper img {
        width: 65%;
    }

    .sale_section_container {
        padding: 70px 70px 0px 70px;
    }

    .vdo_play_btn {
        left: 0px;
    }
}

@media(min-width: 1300px) {
    .vdo_play_btn {
        left: 20px;
    }
}

@media(min-width: 1400px) {
    .vdo_play_btn {
        left: 40px;
    }
}